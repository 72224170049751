import { BuilderType, portalCacheTags } from './portalApiHelpers';

export type User = {};
export type ResetData = {};

export const usersApi = (builder: BuilderType) => ({
  getUsers: builder.query<any, void>({
    query: () => `service/users.json`,
    providesTags: [portalCacheTags.USERS],
  }),

  updateUser: builder.mutation<User, User>({
    query: (user) => ({
      method: 'PUT',
      url: `/service/users/${user.id}.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
  }),

  updateUserPassword: builder.mutation<User, User>({
    query: (user) => ({
      method: 'PUT',
      url: `/service/users/${user.id}/update-password.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
  }),

  updateChargerUserSettings: builder.mutation<User, User>({
    query: (user) => ({
      method: 'PUT',
      url: `/service/users/${user.id}/update-settings.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
  }),

  resetUserPassword: builder.mutation<void, ResetData>({
    query: (resetData) => ({
      method: 'PUT',
      url: `/service/reset-password.json`,
      body: resetData,
    }),
  }),
});
