import { combineSlices } from '@reduxjs/toolkit';
import { authenticateApi } from '../services/portal/authenticateApi';
import { portalApi } from '../services/portal/portalApi';

import authSlice from './slices/auth-slice';
import cacheTags from './slices/cache-tag-slice';
import { projectsApi } from './../services/projects/projectsApi';
import { connectApi } from '../services/connectApi';
import { dashboardsApi } from '../services/projects/dashboardsApi';

const appReducer = combineSlices({
  auth: authSlice,
  cacheTags: cacheTags,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [portalApi.reducerPath]: portalApi.reducer,
  [authenticateApi.reducerPath]: authenticateApi.reducer,
  [connectApi.reducerPath]: connectApi.reducer,
  [dashboardsApi.reducerPath]: dashboardsApi.reducer,
});

const rootReducer = (state = {}, action: any) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
