import { cloneDeep } from 'lodash';
import { BuilderType, projectsCacheTags } from './projectsApiHelpers';

export interface Project {
  id: number;
  client_id: number;
  facility_id: number;
  name: string;
  project_data: Projectdata;
  created_at: string;
  updated_at: string;
  is_archived: boolean | null;
  project_workflows: ProjectWorkflow[];
  client: Client;
}

interface Client {
  id: number;
  portal_account_id: number;
  created_at: string;
  updated_at: string;
  client_type: string;
  total_fleet_size: null | number;
  primary_contact_id: null | number;
  is_archived: boolean | null;
  parent_id: null | number;
  new_client_base_id: null | number;
  channel_type: null | string;
  is_home_account: boolean;
  fy_start_month: null;
  last_updated: string;
  record_version: null | string;
  last_portal_update: null | string;
}

interface ProjectWorkflow {
  id: number;
  project_id: number;
  workflow_type: string;
  current_workflow_phase_id: number;
  date_last_activity: string;
  workflow_data: Workflowdata;
  created_at: string;
  updated_at: string;
  lead_team_resource_id: null | null | number | number;
  current_workflow_phase: CurrentWorkflowPhase;
}

interface CurrentWorkflowPhase {
  id: number;
  workflow_type: string;
  order: number;
  phase_name: string;
  status_on_enter: string;
  status_on_complete: string;
  created_at: string;
  updated_at: string;
}

interface Workflowdata {
  tasks?: any[];
  power_study_start_date?: string;
  reservation_start_date?: string;
  power_study_end_date?: string;
  reservation_end_date?: string;
  workflow_contacts?: any[];
  last_active_pane?: number;
  propsal_started?: boolean;
  logger_list?: Loggerlist[];
  power_study_activated?: boolean;
  technical_contact_id?: number;
  installation_assignment_team_resource?: number;
  pdf_report_options?: Pdfreportoptions;
  some_data?: string;
  preparation_tasks?: Preparationtasks;
  verification_tasks?: Verificationtasks;
}

interface Verificationtasks {
  same_vehicle: boolean;
}

interface Preparationtasks {
  secure_loggers: boolean;
  contact_facility: boolean;
}

interface Pdfreportoptions {
  show_facility_audits: boolean;
  show_notes: boolean;
}

interface Loggerlist {
  id: number;
  name: string;
  status: string;
}

interface Projectdata {
  working_with_dealer?: boolean;
  dealer_id?: number;
  project_contact_ids?: number[];
  facility_contact_ids?: number[];
  some_data?: string;
}

interface ProjectStatsResponse {
  project_stats: Projectstats;
  workflow_stats: Workflowstats;
  client_stats: Clientstats;
  facility_stats: Facilitystats;
  general_stats: Generalstats;
}

interface Generalstats {
  project_creation_dates: string[];
  project_update_dates: string[];
  inactive_projects: number;
}

interface Facilitystats {
  facilities_by_type: Facilitiesbytype;
}

interface Facilitiesbytype {
  [key: string]: number;
  warehouse: number;
  distribution_center: number;
  manufacturing: number;
  cold_storage: number;
  other: number;
  headquarters: number;
}

interface Clientstats {
  total_clients_by_type: Totalclientsbytype;
  average_fleet_size_by_client: string;
  top_active_clients: Topactiveclients;
}

interface Topactiveclients {
  [key: string]: number;
}

interface Totalclientsbytype {
  dealer: number;
  location: number;
  org: number;
  root: number;
  home: number;
  client: number;
}

interface Workflowstats {
  last_activity_by_workflow: Lastactivitybyworkflow;
}

interface Lastactivitybyworkflow {
  [key: string]: string | null;
}

interface Projectstats {
  total_projects_by_client: Totalprojectsbyclient;
  total_workflows_by_type: Totalworkflowsbytype;
  current_workflow_phases: Currentworkflowphases;
  active_vs_archived_projects: Activevsarchivedprojects;
  project_activity_dates: Projectactivitydates;
}

interface Projectactivitydates {
  created_at: string[];
  updated_at: string[];
}

interface Activevsarchivedprojects {
  active: number;
  archived: number;
}

interface Currentworkflowphases {
  '1': number;
  '5': number;
  '8': number;
  '': number;
}

interface Totalworkflowsbytype {
  '': number;
  model: number;
  monitor: number;
  measure: number;
}

interface Totalprojectsbyclient {
  [key: string]: number;
}

export type PaginatedApiResponse<T> = {
  results: T[];
  meta: MetaData;
};
export type MetaData = {
  currentPage: number;
  perPage: number;
  totalEntries: number;
  totalPages: number;
};

export const serviceProjectsApi = (builder: BuilderType) => ({
  createProject: builder.mutation<any, Partial<Project>>({
    query: (project) => ({
      url: `service/projects`,
      method: 'POST',
      body: fixupProjectData(project),
    }),
    invalidatesTags: [projectsCacheTags.PROJECTS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_PROJECT), */
  }),

  getProjects: builder.query<{ result: PaginatedApiResponse<Project>[] }, void>({
    query: () => 'service/projects',
    providesTags: [projectsCacheTags.PROJECTS],
  }),

  getProjectsStats: builder.query<ProjectStatsResponse, void>({
    query: () => 'service/projects/stats',
    providesTags: [projectsCacheTags.PROJECTS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_STATS), */
  }),

  searchProjects: builder.query<
    PaginatedApiResponse<Project>[],
    { pagination?: { page: number; perPage: number } }
  >({
    query: ({ pagination = {}, ...params }) => {
      const paramString = new URLSearchParams(params ?? {}).toString();
      const paginationParams = new URLSearchParams(pagination).toString();
      return `service/projects/search?${paginationParams}&${paramString}`;
    },
  }),

  updateProject: builder.mutation<any, Partial<Project>>({
    query: (project) => ({
      url: `service/projects/${project.id}`,
      method: 'PUT',
      body: fixupProjectData(project),
    }),
    invalidatesTags: [projectsCacheTags.PROJECTS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_PROJECT), */
  }),

  // prettier-ignore
  getOperationsDetail: builder.query<any, { projectId: number; vehicleId: number; date: string }>({
    query: ({ projectId, vehicleId, date }) =>
      `service/projects/${projectId}/vehicles/${vehicleId}/power_logger_detail/${date}.json`,
    providesTags: [projectsCacheTags.PROJECTS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_OPERATIONS_DETAIL), */
  }),
  // prettier-ignore
  createWorkflow: builder.mutation< any, { projectId: number; workflowType: string; workflowData: any }>({
    query: ({ projectId, workflowType, workflowData }) => ({
      url: `service/projects/${projectId}/workflow.json`,
      method: "POST",
      invalidatesTags: [projectsCacheTags.PROJECTS],
      body: fixupWorkflowData(workflowType, workflowData),
    }),
    /* onQueryStarted: legacyFulfill(
      PROJECTS_RECEIVED_CREATE_WORKFLOW
    ),*/
  }),

  // prettier-ignore
  checkOperationsSummary: builder.query<any,{ projectId: number; vehicleId: number }>({
    query: ({ projectId, vehicleId }) =>
      `service/projects/${projectId}/vehicles/${vehicleId}/has_summary_data`,
    providesTags: [projectsCacheTags.PROJECTS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY), */
  }),
});

export function fixupProjectData(project) {
  const copy = cloneDeep(project);
  if (copy.is_archived === undefined) copy.is_archived = false;

  copy.project_data = JSON.stringify(project.project_data);
  copy.project_workflows_attributes = copy.project_workflows;
  delete copy.project_workflows;
  copy.project_workflows_attributes.forEach((workflow) => {
    workflow.workflow_data = JSON.stringify(workflow.workflow_data);
  });

  return copy;
}

export function fixupWorkflowData(workflowType, workflowData) {
  let workflow_data_string = JSON.stringify(workflowData);

  return {
    workflow_data_string: workflow_data_string,
    workflow_type: workflowType,
  };
}
