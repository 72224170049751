import App from './App';
import { createBrowserRouter } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import DashboardDetailPage from './pages/DashboardPage/DashboardDetailPage';
import Monitor from './pages/MonitorPage';
import CustomersPage from './pages/CustomersPage';
import DealersPage from './pages/DealersPage';
import MeasureModelPage from './pages/MeasureModelPage';
import AssetManagerPage from './pages/AssetManagerPage';
import Layout from './components/DashboardLayout';
import ProfilePage from './pages/ProfilePage';
import SimulatorPage from './pages/SimulatorPage';
import StyleGuidePage from './pages/StyleGuidePage';
import ProjectStatus from './pages/ProjectStatus';
import TestPage from './pages/TestPage';
import DashboardRedirect from './pages/DashboardPage/DashboardRedirect';
import NotFoundPage from './pages/NotFoundPage';

export const router = createBrowserRouter([
  {
    Component: App, // root layout route
    children: [
      {
        path: '/',
        Component: Layout,
        children: [
          {
            path: '/',
            Component: DashboardRedirect,
          },
          {
            path: '/dashboard/:id',
            Component: DashboardDetailPage,
          },
          {
            path: '/dashboard',
            Component: DashboardPage,
          },
          {
            path: '/customers',
            Component: CustomersPage,
          },
          {
            path: '/dealers',
            Component: DealersPage,
          },
          {
            path: 'measure-model',
            Component: MeasureModelPage,
          },
          {
            path: 'monitor',
            Component: Monitor,
          },
          {
            path: 'asset-manager/:segment?',
            Component: AssetManagerPage,
          },
          {
            path: 'profile',
            Component: ProfilePage,
          },
          {
            path: 'simulator',
            Component: SimulatorPage,
          },
          {
            path: 'style-guide',
            Component: StyleGuidePage,
          },
          {
            path: 'project-status',
            Component: ProjectStatus,
          },
          {
            path: 'test',
            Component: TestPage,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    Component: NotFoundPage,
  },
]);
