import {
  BarChart,
  BatteryFull,
  FlashOn,
  Home,
  OfflineBolt,
  SystemUpdate,
  WifiTethering,
  Work,
} from '@mui/icons-material';
import AssetIcon from './custom-icons/AssetIcon';
import Binoculars from './custom-icons/Binoculars';
import Handshake from './custom-icons/HandShake';
import PowerLogger from './custom-icons/PowerLogger';
import { NavigationItem } from '@toolpad/core';

type NavItem = NavigationItem & { id: string };

export const navItems: NavItem[] = [
  {
    title: 'Dashboard',
    id: 'dashboard',
    icon: <Home />,
  },
  {
    title: 'Customers',
    id: 'customers',
    segment: 'customers',
    icon: <Work />,
  },
  {
    title: 'Dealers',
    id: 'dealers',
    segment: 'dealers',
    icon: <Handshake />,
  },
  {
    title: 'Measure & Model',
    id: 'measureModel',
    segment: 'measure-model',
    icon: <BarChart />,
  },
  {
    title: 'Monitor',
    id: 'monitor',
    segment: 'monitor',
    icon: <Binoculars />,
  },
  {
    title: 'Asset Manager',
    id: 'assetManager',
    segment: 'asset-manager',
    icon: <AssetIcon />,
    children: [
      {
        title: 'Chargers',
        id: 'chargers',
        icon: <FlashOn />,
        segment: 'chargers',
      },
      {
        title: 'Batteries',
        id: 'batteries',
        icon: <BatteryFull />,
        segment: 'batteries',
      },
      {
        title: 'gateways',
        id: 'gateways',
        icon: <WifiTethering />,
        segment: 'gateways',
      },
      {
        title: 'Power loggers',
        id: 'power-loggers',
        icon: <PowerLogger />,
        segment: 'power-loggers',
      },
      {
        title: 'Vanadium BESS',
        id: 'bess',
        icon: <OfflineBolt />,
        segment: 'bess',
      },
      {
        title: 'LEAD acid BESS',
        id: 'lead-acid',
        icon: <OfflineBolt />,
        segment: 'lead-acid',
      },
      {
        title: 'software-update',
        id: 'software-update',
        icon: <SystemUpdate />,
        segment: 'software-update',
      },
    ],
  },
];
