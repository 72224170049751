import { Add, Fullscreen, StarRounded, Widgets } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocalStorageState } from '@toolpad/core';
import { debounce, keyBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import WidgetPicker from '../../components/WidgetPicker';
import {
  Dashboard as DashboardType,
  DashboardWidget,
  useGetDashboardQuery,
  useUpdateDashboardMutation,
} from '../../services/projects/dashboardsApi';
import { DashboardContextProvider } from './DashboardContext';
import DashboardMenu from './DashboardMenu';
import WidgetCard from './WidgetCard';
import { useUser } from '../../hooks/useUser';
import { seicLight } from '../../themes';

const Dashboard: React.FC = () => {
  const { id } = useParams();
  const { data: dashboard, isLoading } = useGetDashboardQuery(id, { skip: !id });
  const { details } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPalm = useMediaQuery(theme.breakpoints.down('lg'));

  const [updateDashboard, { isLoading: isUpdating }] = useUpdateDashboardMutation();

  const [containerWidth, setContainerWidth] = useState(1200);

  const [toEditWidget, setToEditWidget] = useState<DashboardWidget | null>(null);
  const [_, setRecentDashboards] = useLocalStorageState('recentDashboards', '[]');

  const [editableDashboard, setEditableDashboard] = useState<DashboardType>();

  const editing = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);

  // Update recent dashboards
  useEffect(() => {
    if (dashboard?.id) {
      const recentDashboards = JSON.parse(localStorage.getItem('recentDashboards') || '[]');
      const updatedDashboards = [
        dashboard,
        ...recentDashboards.filter((d: string) => d.id !== dashboard.id),
      ].slice(0, 4);
      setRecentDashboards(JSON.stringify(updatedDashboards));
    }
  }, [dashboard, setRecentDashboards]);

  useEffect(() => {
    if (!editing.current) {
      setEditableDashboard(structuredClone(dashboard));
    }
  }, [dashboard]);

  // Resize observer for main container
  useLayoutEffect(() => {
    // set initial width
    if (!mounted.current && containerRef.current) {
      mounted.current = true;
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
    }
    const handleResize = debounce(() => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
      }
    }, 500);

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleFullScreen = async () => {
    if (!containerRef.current) return;
    await containerRef.current?.requestFullscreen();
    // sets the container width to the full width of the screen without waiting for debounce
    setTimeout(() => {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
    }, 50);
  };

  const handleEditWidget = useCallback((w: DashboardWidget) => {
    setToEditWidget(w);
    setWidgetPickerOpen(true);
  }, []);

  const handleLayoutChange = useCallback(
    debounce(async (layout: any) => {
      editing.current = false;
      const widgets = structuredClone(editableDashboard?.data?.widgets ?? []);

      // Check if layout has changed
      if (
        JSON.stringify(layout) ===
        JSON.stringify(widgets.map((w) => ({ i: w.id, x: w.x, y: w.y, w: w.w, h: w.h })))
      ) {
        return;
      }
      const newLayout = keyBy(layout, 'i');
      widgets.forEach((w) => {
        w.x = newLayout[w.id].x;
        w.y = newLayout[w.id].y;
        w.w = newLayout[w.id].w;
        w.h = newLayout[w.id].h;
      });
      try {
        await updateDashboard({
          ...editableDashboard,
          data: {
            ...editableDashboard?.data,
            widgets,
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Error saving dashboard', { variant: 'error' });
      }
    }, 2000),
    [editableDashboard, isLoading, updateDashboard]
  );

  const handleWidgetPickerClose = useCallback(() => {
    setWidgetPickerOpen(false);
    setToEditWidget(null);
  }, []);

  const layout = useMemo(() => {
    const _layout =
      structuredClone(
        editableDashboard?.data?.widgets.map((w) => ({ ...w, i: w.id, static: false }))
      ) ?? [];
    if (isMobile) {
      let y = 0;
      return _layout.map((w) => {
        const tmp = { ...w, y, w: 8, static: true };
        y = w.h + y;
        return tmp;
      });
    }
    if (isPalm) {
      _layout?.sort((a, b) => a.x * a.y + 1 - b.x * b.y + 1);
      let leftY = 0;
      let rightY = 0;
      _layout.forEach((w, index) => {
        w.w = 4;
        w.static = true;
        if (leftY <= rightY) {
          w.x = 0;
          w.y = leftY;
          leftY += w.h;
        } else {
          w.x = 4;
          w.y = rightY;
          rightY += w.h;
        }
      });
      return _layout;
    }

    return _layout;
  }, [editableDashboard, isMobile, isPalm]);
  const [widgetPickerOpen, setWidgetPickerOpen] = useState(false);
  const { data } = editableDashboard ?? {};
  const widgets = (data?.widgets ?? []).map((i) => ({ ...i, i: i.id, static: false }));
  console.log('rendering');
  return (
    <DashboardContextProvider>
      <PageContainer
        title={
          isLoading ? (
            <Skeleton width={200} />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {details?.defaultDashboard === editableDashboard?.id && (
                <Tooltip title="Default dashboard">
                  <StarRounded sx={{ color: seicLight.atRisk }} />
                </Tooltip>
              )}
              {editableDashboard?.name}
            </Box>
          )
        }
        actions={
          <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {isUpdating && <CircularProgress size={24} />}
              {!!editableDashboard && (
                <Button variant="contained" onClick={() => setWidgetPickerOpen(true)}>
                  <Add /> Add Widget
                </Button>
              )}

              {!!editableDashboard && (
                <>
                  <Tooltip title="Full screen mode">
                    <IconButton onClick={handleFullScreen}>
                      <Fullscreen />
                    </IconButton>
                  </Tooltip>
                  <DashboardMenu
                    dashboard={editableDashboard}
                    onClone={({ id }) => navigate(`/dashboard/${id}`)}
                  />
                </>
              )}
            </Box>
          </>
        }
      >
        <Box
          ref={containerRef}
          sx={{
            '&:-webkit-full-screen': {
              background: theme.palette.background.default,
            },
            '.react-grid-item.react-grid-placeholder': {
              background: theme.palette.background.primaryHover,
            },
            '.react-resizable-handle::after': {
              borderColor: theme.palette.secondary.main,
            },
          }}
        >
          <GridLayout
            className="grid-layout"
            layout={layout}
            cols={8}
            rowHeight={150}
            width={containerWidth}
            margin={[20, 20]}
            useCSSTransforms
            onDragStart={() => (editing.current = true)}
            onResizeStart={() => (editing.current = true)}
            onDrag={() => (editing.current = true)}
            onResize={() => (editing.current = true)}
            onDragStop={handleLayoutChange}
            onResizeStop={handleLayoutChange}
            draggableHandle=".drag-handle"
          >
            {widgets.map((w) => (
              <WidgetCard
                key={w.id}
                widget={w}
                dashboard={editableDashboard!}
                onEdit={handleEditWidget}
              />
            ))}
          </GridLayout>
        </Box>
        {!!editableDashboard && !!widgetPickerOpen && (
          <WidgetPicker
            dashboard={editableDashboard}
            open={widgetPickerOpen}
            toEdit={toEditWidget}
            onClose={handleWidgetPickerClose}
          />
        )}
        {isLoading && (
          <Grid2 container spacing={0.5}>
            {Array.from({ length: 4 }).map((_, i) => (
              <Grid2 size={{ xs: 3 }} key={i} sx={{ p: 2, flex: 1 }}>
                <Skeleton variant="rectangular" height={180} />
              </Grid2>
            ))}
          </Grid2>
        )}
        {!widgets.length && !isLoading && (
          <Box display="grid" justifyContent="center" alignItems="center" p={12} textAlign="center">
            <div>
              <Box
                sx={{
                  borderRadius: '50%',
                  padding: '2em',
                  background: theme.palette.background.surface1,
                  display: 'inline-block',
                  alignSelf: 'center',
                }}
              >
                <Widgets sx={{ fontSize: 120, justifySelf: 'center' }} />
              </Box>
            </div>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Start by adding your first widget
            </Typography>
            <Typography variant="body1">
              Widgets are your data visualizations. Click the "Add Widget" button to get started.
            </Typography>
          </Box>
        )}
      </PageContainer>
    </DashboardContextProvider>
  );
};

export default Dashboard;
