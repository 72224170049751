import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useEffect } from 'react';

const DashboardRedirect = () => {
  const { pathname } = useLocation();
  const { details: userDetails, isLoadingDetails } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoadingDetails) return;
    if (!pathname.includes('dashboard') && userDetails?.defaultDashboard)
      navigate(`/dashboard/${userDetails.defaultDashboard}`);
    else navigate('/dashboard');
  }, [isLoadingDetails, navigate, pathname, userDetails?.defaultDashboard]);
  return null;
};

export default DashboardRedirect;
