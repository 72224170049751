import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetUserDetailsQuery,
  useLazyGetUserConfigsQuery,
} from '../services/projects/projectsApi';
import { navItems } from '../sideNavigation';
import { AppState } from '../store/store';

const useUser = () => {
  const { user: authUser, exp } = useSelector((state: AppState) => state.auth);
  const [fetchUserConfigs, { data: userConfig, isError, isLoading }] = useLazyGetUserConfigsQuery();
  const isAuthenticated = !!authUser && exp > Date.now() / 1000;

  const { data: details, isLoading: isLoadingDetails } = useGetUserDetailsQuery(undefined, {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserConfigs();
    }
  }, [isAuthenticated, fetchUserConfigs]);

  const nav = useMemo(() => {
    const navItemsByKey = keyBy(navItems, 'id');
    return (userConfig?.appConfig?.menu ?? []).map((menuItem) => {
      return navItemsByKey[menuItem.nav] ?? { kind: 'divider' };
    });
  }, [userConfig]);

  return {
    isAuthenticated,
    userConfig,
    isLoading,
    isError,
    nav,
    auth: authUser,
    details,
    isLoadingDetails,
  };
};

export { useUser };
