import { Dashboard } from '../services/projects/dashboardsApi';

export const findWidgetPossiblePosition = (w: number, h: number, dashboard: Dashboard) => {
  // Given a grid of 8xn, find the first available position in the current dashboard
  const { widgets } = dashboard.data;
  let x = 0;
  let y = 0;
  let found = false;

  const isPositionAvailable = (x: number, y: number, w: number, h: number) => {
    return !widgets.some(
      (widget) =>
        x < widget.x + widget.w && x + w > widget.x && y < widget.y + widget.h && y + h > widget.y
    );
  };

  while (!found) {
    if (isPositionAvailable(x, y, w, h)) {
      found = true;
    } else {
      x++;
      if (x >= 8) {
        x = 0;
        y++;
      }
    }
  }

  return { x, y, w, h };
};
