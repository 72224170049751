import { Widgets } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class WidgetErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('WidgetErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          alignItems="center"
          height="400px"
          width="100%"
          textAlign="center"
          sx={{ color: 'text.secondary' }}
        >
          <Widgets sx={{ height: 100, width: 'auto' }} />
          <Typography variant="h6">Widget preview not available yet</Typography>
          <Typography variant="body2">Please enter required configuration details</Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default WidgetErrorBoundary;
