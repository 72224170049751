import { FormatListBulleted, History, MoreVert, StarRounded, Widgets } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useLocalStorageState } from '@toolpad/core';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import { useUser } from '../../hooks/useUser';
import {
  Dashboard,
  useCreateDashboardMutation,
  useGetDashboardsQuery,
} from '../../services/projects/dashboardsApi';
import { seicLight } from '../../themes';
import DashboardMenu from './DashboardMenu';

const DashboardPage: React.FC = () => {
  const { pathname } = useLocation();
  const { details: userDetails } = useUser();
  const navigate = useNavigate();
  if (pathname === '/' && userDetails?.defaultDashboard)
    navigate(`/dashboard/${userDetails.defaultDashboard}`);

  const { data: dashboards, isError, isLoading } = useGetDashboardsQuery();
  const [createDashboard, { isLoading: isCreating }] = useCreateDashboardMutation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [recentDashboards] = useLocalStorageState('recentDashboards', '[]');

  const handleSaveNewDashboard = async () => {
    try {
      const { id } = await createDashboard({
        name: dashboardName,
        dashboard_type: 'dashboard',
        data: {
          widgets: [],
        },
      }).unwrap();
      setOpen(false);
      enqueueSnackbar('Dashboard created', { variant: 'success' });
      navigate(`/dashboard/${id}`);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error saving dashboard', { variant: 'error' });
    }
  };

  const [filteredDashboards, filteredRecent] = useMemo(() => {
    if (!dashboards) return [];
    const filtered = dashboards.filter((dashboard) =>
      dashboard.name.toLowerCase().includes(search.toLowerCase())
    );
    let recent = [];
    try {
      recent = JSON.parse(recentDashboards ?? '[]').filter((dashboard: Dashboard) =>
        dashboard.name.toLowerCase().includes(search.toLowerCase())
      );
    } catch (e) {
      console.log(e);
    }
    return [filtered, recent];
  }, [dashboards, recentDashboards, search]);

  return (
    <PageContainer
      actions={
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, justifyContent: 'flex-end' }}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            variant="filled"
            size="small"
            label="Search"
            sx={{ mb: 2, minWidth: '300px' }}
          ></TextField>
          <Button
            variant="contained"
            size="large"
            sx={{ textWrap: 'nowrap' }}
            onClick={() => setOpen(true)}
            disabled={isCreating}
          >
            <Widgets sx={{ mr: 1 }} /> New Dashboard
          </Button>
        </Box>
      }
    >
      {isError ? (
        <Typography color="error">Error loading dashboards</Typography>
      ) : (
        <Box>
          <Box sx={{ textAlign: 'lef' }}>
            <Typography
              variant="h6"
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              gutterBottom
            >
              <History /> Recent
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isLoading &&
                !filteredRecent?.length &&
                Array.from({ length: 5 }).map((_, i) => (
                  <Skeleton sx={{ flex: 1, height: 146, boxSizing: 'border-box' }} key={i} />
                ))}
              {filteredRecent?.map((dashboard) => (
                <Card sx={{ flex: 1, backgroundColor: 'background.surface4' }}>
                  <CardActionArea
                    sx={{ p: 4 }}
                    onClick={() => navigate(`/dashboard/${dashboard.id}`)}
                  >
                    <Typography variant="h6">
                      <Box sx={{ position: 'relative' }}>
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>
                          <Widgets sx={{ color: 'white' }} />
                        </Avatar>
                        {dashboard.id === userDetails?.defaultDashboard && (
                          <StarRounded
                            sx={{
                              color: seicLight.atRisk,
                              position: 'absolute',
                              left: '-12px',
                              top: '-12px',
                              fontSize: '33px',
                            }}
                          />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>{dashboard.name}</Box>
                    </Typography>
                    <Typography variant="body2">
                      Widgets: {dashboard.data?.widgets.length ?? 0}
                    </Typography>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
            <Typography
              variant="h6"
              sx={{ mt: 2, display: 'flex', gap: 1, alignItems: 'center' }}
              gutterBottom
            >
              <FormatListBulleted /> All Dashboards
            </Typography>
            <List dense>
              {isLoading &&
                !filteredDashboards?.length &&
                Array.from({ length: 5 }).map((_, i) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Skeleton variant="circular">
                      <Avatar />
                    </Skeleton>
                    <Skeleton sx={{ flex: 1, height: 60, boxSizing: 'border-box' }} key={i} />
                  </Box>
                ))}
              {filteredDashboards?.map((dashboard) => (
                <ListItem
                  dense
                  disablePadding
                  sx={{ mb: 1 }}
                  onClick={() => navigate('/dashboard/' + dashboard.id)}
                  secondaryAction={
                    <DashboardMenu dashboard={dashboard}>
                      <MoreVert />
                    </DashboardMenu>
                  }
                >
                  <ListItemButton>
                    <ListItemAvatar sx={{ position: 'relative' }}>
                      <Avatar sx={{ bgcolor: 'secondary.main' }}>
                        <Widgets sx={{ color: 'white' }} />
                      </Avatar>
                      {dashboard.id === userDetails?.defaultDashboard && (
                        <StarRounded
                          sx={{
                            color: seicLight.atRisk,
                            position: 'absolute',
                            left: '-12px',
                            top: '-12px',
                            fontSize: '33px',
                          }}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>{dashboard.name}</Box>
                      }
                      secondary={`Widgets: ${dashboard.data?.widgets.length ?? 0}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle>Create New Dashboard</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Dashboard Name"
            type="text"
            fullWidth
            variant="filled"
            value={dashboardName}
            onChange={(e) => setDashboardName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            loading={isCreating}
            variant="contained"
            onClick={handleSaveNewDashboard}
            disabled={!dashboardName || isCreating}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default DashboardPage;
