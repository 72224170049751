import React, { createContext, useContext, useState, ReactNode } from 'react';

type DashboardFilters = { clientId: number; projectId: number; startDate: Date; endDate: Date };

export interface DashboardContextProps {
  filters: DashboardFilters;
  setFilters: React.Dispatch<React.SetStateAction<DashboardFilters>>;
}

export const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);
DashboardContext.displayName = 'DashboardContext';

export const DashboardContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<{
    clientId: number;
    projectId: number;
    startDate: Date;
    endDate: Date;
  }>();

  return (
    <DashboardContext.Provider value={{ filters, setFilters }}>
      {children}
    </DashboardContext.Provider>
  );
};
