import { BuilderType, projectsCacheTags } from './projectsApiHelpers';

interface UserDetails {
  theme: 'light' | 'dark';
  defaultDashboard?: number;
}

export const userDetailsApi = (builder: BuilderType) => ({
  getUserDetails: builder.query<UserDetails, void>({
    query: () => ({
      url: `service/user-details`,
      method: 'GET',
    }),
    providesTags: [projectsCacheTags.USER_DETAILS],
  }),
  updateUserDetails: builder.mutation<UserDetails, UserDetails>({
    query: (body) => ({
      url: `service/user-details`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: [projectsCacheTags.USER_DETAILS],
  }),
});
