import React from 'react';

import { Box, Grid2, useMediaQuery, useTheme } from '@mui/material';
import { AuthProvider, SignInPage } from '@toolpad/core';
import { useDispatch } from 'react-redux';
import LoginBackdrop from '../assets/loginBackdrop.svg';
import { useAuthenticateMutation } from '../services/portal/authenticateApi';
import { setCredentials } from '../store/slices/auth-slice';

const SignIn: React.FC = () => {
  const [authenticate] = useAuthenticateMutation();

  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const small = useMediaQuery(breakpoints.down('lg'));

  const providers = [{ id: 'credentials', name: 'Email and Password', icon: <Box>hol</Box> }];

  const signIn: (provider: AuthProvider, formData: FormData) => void = async (
    provider,
    formData
  ) => {
    const username = formData.get('email') as string;
    const password = formData.get('password') as string;
    try {
      const { auth_token, email } = await authenticate({
        email: username,
        password,
      }).unwrap();
      dispatch(setCredentials({ email, token: auth_token }));
    } catch (e: any) {
      console.log(e);
      return {
        type: 'CredentialsSignin',
        error: 'Invalid credentials.',
      };
    }
  };

  return (
    <Grid2 container alignItems="center" justifyContent="center" height={'100vh'}>
      <Grid2
        size={{ lg: 7, xs: 0 }}
        sx={{ display: small ? 'none' : 'flex' }}
        justifyContent="center"
      >
        <Box className="scroll-container" sx={{ backgroundImage: `url(${LoginBackdrop})` }}></Box>
      </Grid2>
      <Grid2 data-testid="abcd" size={{ lg: 5, xs: 12 }} justifyContent="center">
        <SignInPage data-testid="sign-in-component" signIn={signIn} providers={providers} />
      </Grid2>
    </Grid2>
  );
};

export default SignIn;
