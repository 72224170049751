import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import { router } from './routes';
import { DialogsProvider } from '@toolpad/core';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DialogsProvider>
          <SnackbarProvider autoHideDuration={3000} />
          <RouterProvider router={router} />
        </DialogsProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
