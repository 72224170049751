import '../App.css';
import { Container, Typography, Button, Box, ThemeProvider, Grid2 } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { darkTheme, lightTheme } from '../themes';
import ErrorBackdrop from '../assets/errorBackdrop.svg';
import { ArrowBack } from '@mui/icons-material';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid2 container spacing={2}>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{
              img: {
                width: { xs: '50%', sm: 'auto' },
              },
            }}
          >
            <img src={ErrorBackdrop} alt="Error" />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="h1" sx={{ fontSize: 120 }} gutterBottom>
              404
            </Typography>
            <Typography variant="h1" gutterBottom>
              Page Not Found
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Sorry, the page you are looking for does not exist.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                sx={{ width: 400 }}
                color="primary"
                onClick={handleGoHome}
              >
                <ArrowBack /> Go to Home
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </ThemeProvider>
  );
};

export default NotFoundPage;
