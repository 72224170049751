import { Delete, DragIndicator, Edit, FileCopy, MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Collapse,
  Divider,
  Fade,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useDialogs } from '@toolpad/core';
import { enqueueSnackbar } from 'notistack';
import { forwardRef, Suspense, useMemo, useState } from 'react';
import { widgets } from '../../components/widgets/utils/widgetList';
import WidgetErrorBoundary from '../../components/widgets/WidgetErrorBoundary';
import {
  Dashboard,
  DashboardWidget,
  useUpdateDashboardMutation,
} from '../../services/projects/dashboardsApi';
import { findWidgetPossiblePosition } from '../../utils/dashboardUtils';

type WidgetCardProps = {
  widget: DashboardWidget;
  dashboard: Dashboard;
  onEdit?: (widget: DashboardWidget) => void;
  onDuplicate?: (widget: DashboardWidget) => void;
  onDelete?: (id: string) => void;
  style?: React.CSSProperties;
  className?: string;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
};

const WidgetCard = forwardRef(
  (
    {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
      widget,
      dashboard,
      onEdit,
      ...props
    }: WidgetCardProps,
    ref
  ) => {
    const WidgetComponent = useMemo(() => widgets[widget.type]?.component, [widget.type]);
    const [anchorWidgetEl, setAnchorWidgetEl] = useState<null | HTMLElement>(null);
    const [updateDashboard, { isLoading: isUpdating }] = useUpdateDashboardMutation();

    const dialogs = useDialogs();
    const ActionArea = useMemo(
      () => (widgets[widget.type]?.disableRipple ? Box : CardActionArea),
      [widget.type]
    );

    const handleWidgetMenuClose = () => {
      setAnchorWidgetEl(null);
    };

    const handleEditWidget = () => {
      handleWidgetMenuClose();
      onEdit?.(widget);
    };

    const handleDeleteWidget = async () => {
      handleWidgetMenuClose();
      if (
        await dialogs.confirm('Are you sure you want to delete this widget?', {
          title: 'Delete Widget',
          okText: 'Delete',
        })
      ) {
        const newWidgets = dashboard?.data?.widgets.filter((w) => w.id !== widget.id);
        try {
          await updateDashboard({
            ...dashboard!,
            data: {
              ...dashboard?.data,
              widgets: newWidgets,
            },
          }).unwrap();
          enqueueSnackbar('Widget deleted', { variant: 'info' });
        } catch (error) {
          console.log(error);
          enqueueSnackbar('Error deleting widget', { variant: 'error' });
        }
      }
    };

    const handleDuplicateWidget = async () => {
      handleWidgetMenuClose();
      const position = findWidgetPossiblePosition(widget.w, widget.h, dashboard);
      const newWidget = { ...widget, id: new Date().toString(36).substring(7), ...position };

      try {
        await updateDashboard({
          ...dashboard!,
          data: {
            ...dashboard?.data,
            widgets: [...(dashboard?.data?.widgets ?? []), newWidget],
          },
        }).unwrap();
        enqueueSnackbar('Widget duplicated', { variant: 'info' });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Error duplicating widget', { variant: 'error' });
      }
    };

    return (
      <Card
        key={widget.id}
        style={{ transformOrigin: '0 0 0', ...style }}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        data-id={widget.id}
        sx={{
          ':hover': {
            '.widget-hover-action': {
              opacity: 1,
            },
          },
        }}
        {...props}
      >
        <WidgetErrorBoundary>
          <CardHeader
            sx={{ p: '2px' }}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'left',
            }}
            avatar={
              <DragIndicator
                sx={{ opacity: 0, cursor: 'grab' }}
                className="drag-handle widget-hover-action"
              />
            }
            title={widget.config?.title}
            action={
              <IconButton
                className="widget-menu widget-hover-action"
                sx={{ opacity: 0 }}
                onClick={(e) => {
                  console.log('setting anchor');
                  e.stopPropagation();
                  setAnchorWidgetEl(e.currentTarget);
                }}
              >
                <MoreVert />
              </IconButton>
            }
          />
          <ActionArea
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ height: '100%', overflow: 'scroll' }}
          >
            <Box sx={{ height: '100%', width: '100%' }}>
              <Suspense>
                {WidgetComponent ? (
                  <WidgetComponent {...widget.config} />
                ) : (
                  <Typography variant="body1">Widget not found</Typography>
                )}
              </Suspense>
            </Box>
          </ActionArea>
        </WidgetErrorBoundary>
        <Menu anchorEl={anchorWidgetEl} open={!!anchorWidgetEl} onClose={handleWidgetMenuClose}>
          <MenuItem onClick={handleEditWidget}>
            <Edit sx={{ mr: 1 }} />
            Edit Widget
          </MenuItem>
          <MenuItem onClick={handleDuplicateWidget}>
            <FileCopy sx={{ mr: 1 }} />
            Duplicate Widget
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleDeleteWidget}>
            <Delete sx={{ mr: 1 }} /> Delete Widget
          </MenuItem>
        </Menu>
        {children}
      </Card>
    );
  }
);

export default WidgetCard;
