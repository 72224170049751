import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import createIndexedDBStorage from 'redux-persist-indexeddb-storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { authenticateApi } from '../services/portal/authenticateApi';
import { portalApi } from '../services/portal/portalApi';
import { projectsApi } from '../services/projects/projectsApi';
import rootReducer from './rootReducer';
import { connectApi } from '../services/connectApi';
import { dashboardsApi } from '../services/projects/dashboardsApi';

// Create indexedDB storage
const storage = createIndexedDBStorage({
  name: 'myApp',
  storeName: 'persistStore',
});

// Persist config for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'auth',
    'cacheTags',
    authenticateApi.reducerPath,
    projectsApi.reducerPath,
    portalApi.reducerPath,
  ], // List reducers to be persisted
} as PersistConfig<any>;

export type StoreState = ReturnType<typeof rootReducer>;
// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStore = (reducer: ConfigureStoreOptions['reducer'], preloadedState?: AppState) => {
  return configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat([
        projectsApi.middleware,
        portalApi.middleware,
        authenticateApi.middleware,
        connectApi.middleware,
        dashboardsApi.middleware,
      ]),
  });
};

// Create the store with the persisted reducer
export const store = createStore(persistedReducer);
// Define a type for the root state
export type AppState = ReturnType<typeof rootReducer>;

export const mockStore = (initialState: Partial<AppState>) =>
  createStore(rootReducer, initialState);

// Create a persistor
export const persistor = persistStore(store);
