import React, { useState } from 'react';
import PageContainer from '../components/PageContainer';
import { Box, Button, Typography } from '@mui/material';

const TestPage: React.FC = () => {
  const [detailOpen, setDetailOpen] = useState(false);
  return (
    <PageContainer>
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box
          sx={{
            transform: detailOpen ? 'translateX(-50%)' : 'none',
            transition: 'transform 0.3s ease-in-out',
            width: '200%',
            display: 'flex',
          }}
        >
          <Box
            flex={1}
            sx={{ opacity: detailOpen ? 0 : 1, transition: 'opacity, 0.3s ease-in-out' }}
          >
            <Button onClick={() => setDetailOpen(!detailOpen)}>Toggle Detail</Button>
          </Box>
          <Box
            flex={1}
            sx={{
              flex: 1,
              opacity: detailOpen ? 1 : 0,
              transition: 'opacity, 0.3s ease-in-out',
            }}
          >
            <Typography variant="h1">Detail Page</Typography>
            <Button onClick={() => setDetailOpen(!detailOpen)}>Toggle Detail</Button>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default TestPage;
