import { BuilderType, projectsCacheTags } from './projectsApiHelpers';

import { Resource } from './resourcesApi';

export interface NewTeamResponse {
  new_teams: NewTeam[];
  new_team_resources: NewTeamResource[];
  users: User[];
  goals: Goal[];
  client_owners: Clientowner[];
}

interface Clientowner {
  id: number;
  client_id: number;
  target_client_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}

interface Goal {
  id: number;
  client_id: number;
  node_type: string;
  node_id: number;
  measure_goal: number;
  model_goal: number;
  monitor_goal: number;
  created_at: string;
  updated_at: string;
}

interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  locale: null | string;
  temp_unit: string;
  account_users: AccountUser[];
  groups: Group[];
  profile_image_url?: string;
}

interface Group {
  id: number;
  account_id: number;
  group_definition_id: number;
}

interface AccountUser {
  id: number;
  account_id: number;
  user_id: number;
}

interface NewTeamResource {
  id: number;
  client_id: number;
  team_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}

interface NewTeam {
  id: number;
  client_id: number;
  team_name: string;
  team_lead_user_id: number;
  created_at: string;
  updated_at: string;
}

export const newTeamsApi = (builder: BuilderType) => ({
  getNewTeams: builder.query<NewTeamResponse, void>({
    query: () => `service/new-teams`,
    providesTags: [
      projectsCacheTags.NEW_TEAMS,
      projectsCacheTags.NEW_TEAM_RESOURCES,
      projectsCacheTags.GOALS,
      projectsCacheTags.CLIENT_OWNERS,
    ],
  }),

  createNewTeam: builder.mutation<NewTeam, any>({
    query: (team) => ({
      url: `service/new-teams`,
      method: 'POST',
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  updateNewTeam: builder.mutation<NewTeam, any>({
    query: (team) => ({
      url: `service/new-teams/${team.id}`,
      method: 'PUT',
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  deleteNewTeam: builder.mutation<NewTeam, number>({
    query: (id) => ({
      url: `service/new-teams/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  createNewTeamResource: builder.mutation<Resource, any>({
    query: (teamResource) => ({
      url: `service/new-teams/resources`,
      method: 'POST',
      body: teamResource,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  deleteNewTeamResource: builder.mutation<Resource, number>({
    query: (id) => ({
      url: `service/new-teams/resources/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),
});
