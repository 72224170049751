import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { projectsCacheTags } from './projectsApiHelpers';
import { basicHeaders } from '../utils';

export const providedTags = ['dashboard'];
export type Dashboard = {
  id: number;
  name: string;
  dashboard_type: string;
  data: {
    widgets: DashboardWidget[];
  };
  owner_id: number;
  is_public: boolean;
  is_default: boolean;
  is_editable: boolean;
};

export type DashboardWidget = {
  id: string;
  type: string;
  x: number;
  y: number;
  w: number;
  h: number;
  config: any;
};
export const dashboardsApi = createApi({
  reducerPath: 'dashboardsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_PROJECTS_SERVICE}/`,
    prepareHeaders: basicHeaders,
  }),
  tagTypes: [projectsCacheTags.DASHBOARDS],
  endpoints: (builder) => ({
    getDashboards: builder.query<Dashboard[], void>({
      query: () => `service/dashboard`,
      providesTags: [projectsCacheTags.DASHBOARDS],
    }),

    getDashboard: builder.query<Dashboard, number>({
      query: (id) => `service/dashboard/${id}`,
      providesTags: (result) => [{ type: projectsCacheTags.DASHBOARDS, id: result?.id }],
    }),
    createDashboard: builder.mutation<Partial<Dashboard>, Partial<Dashboard>>({
      query: (body) => ({
        url: `service/dashboard`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [projectsCacheTags.DASHBOARDS],
    }),

    updateDashboard: builder.mutation<Dashboard, Partial<Dashboard>>({
      query: (body) => ({
        url: `service/dashboard/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [projectsCacheTags.DASHBOARDS],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          dashboardsApi.util.updateQueryData('getDashboard', id, (draft) => {
            Object.assign(draft, patch);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteDashboard: builder.mutation<void, number>({
      query: (id) => ({
        url: `service/dashboard/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [projectsCacheTags.DASHBOARDS],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetDashboardsQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
} = dashboardsApi;
