import { createTheme, ThemeOptions } from '@mui/material';

export const indicatorColors = {
  good: '#47B56F',
  warning: '#FFC145',
  bad: '#E34E46',
  notReporting: '#848A94',
  charging: '#7673FF',
  discharging: '#B767E5',
  idle: '#56B4F0',
};

export const lightChartColors = [
  '#0074DE',
  '#5E63D2',
  '#5D36B4',
  '#A126A0',
  '#D61C71',
  '#ED0020',
  '#EF4E00',
  '#FBA841',
  '#979F34',
  '#006753',
];

export const darkChartColors = [
  '#3AB3FF',
  '#7AA9FF',
  '#9E7DD7',
  '#D772C7',
  '#FF68A6',
  '#FF5C69',
  '#FF8549',
  '#FFBF47',
  '#CBE364',
  '#72C0B3',
];

export const seicLight = {
  background: '#F2F6FC',
  onBackground: '#1B2328',
  tooltipBackground: '#FFFFFF',
  surface1: '#FFFFFF',
  onSurface1: '#FFFFFF',
  surface2: '#EAF1FC',
  onSurface2: '#F0F1F4',
  surface3: '#E1EBFA',
  onSurface3: '#F0F1F4',
  surface4: '#D7E4F7',
  inputSurface: '#0000000C',
  menuSurface: '#FFFFFF',
  outline: '#CFD4DB',
  outlineAlt: '#F0F1F4',
  textHighEmphasis: '#25344A',
  textMediumEmphasis: '#5B6B82',
  textLowEmphasis: '#8C9EB8',
  disabled: '#99ADCC',

  primary: '#00B2A9',
  primary600: '#004744',
  primary500: '#006B65',
  primary400: '#008E87',
  primary300: '#00B2A9',
  primary200: '#80D9D4',
  primary100: '#B3E8E5',
  onPrimary: '#FFFFFF',
  primaryHover: '#00B2A914',

  secondary: '#0088FF',
  secondary600: '#1943B9',
  secondary500: '#1463D8',
  secondary400: '#0088FF',
  secondary300: '#2FA6FF',
  secondary200: '#8DCBFF',
  secondary100: '#BADFFF',
  onSecondary: '#0A1B4A',

  critical: '#E64B39',
  atRisk: '#FFAB00',
  good: '#46A669',
  noData: '#768294',

  chart1: '#0074DE',
  chart2: '#5E63D2',
  chart3: '#5D36B4',
  chart4: '#A126A0',
  chart5: '#D61C71',
  chart6: '#ED0020',
  chart7: '#EF4E00',
  chart8: '#FBA841',
  chart9: '#979F34',
  chart10: '#006753',

  pdfBackground: '#FFFFFF',
  pdfSurface1: '#F8F8FA',
  pdfSurface2: '#F0F1F4',
  pdfOutline: '#E0E3E9',
  pdfTextHighEmphasis: '#18212F',
  pdfTextMediumEmphasis: '#4B555B',
  pdfTextOnColor: '#FFFFFF',
  pdfPrimary: '#00B2A9',
  pdfPrimaryDark: '#006865',
  pdfPrimaryLight: '#B3E8E5',
  pdfSecondary: '#00B2A9',
  pdfSecondaryDark: '#00245B',
  pdfSecondaryLight: '#CCD5E3',
  pdfCritical: '#E64B39',
  pdfAtRisk: '#FFAB00',
  pdfGood: '#46A669',
  pdfNoData: '#768294',
  pdfChartPurple: '#5E63D2',
  pdfChartPink: '#D61C71',
  pdfChartOrange: '#EF4E00',
  pdfChartBlue: '#0074DE',

  level6: '#595C66',
  level8: '#A6A8AE',
};

export const seicDark = {
  background: '#18212F',
  onBackground: '#FFFFFF',
  tooltipBackground: '#090E15',
  surface1: '#1D293B',
  onSurface1: '#26364D',
  surface2: '#26364D',
  onSurface2: '#2A3B54',
  surface3: '#2A3B54',
  onSurface3: '#2E3E57',
  surface4: '#2E3E57',
  onSurface4: '#2E3E57',
  inputSurface: '#FFFFFF0C',
  menuSurface: '#26364D',
  outline: '#2A3B54',
  outlineAlt: '#18212F',
  textHighEmphasis: '#FFFFFF',
  textMediumEmphasis: '#B3BBC7',
  textLowEmphasis: '#768294',
  disabled: '#4C555B',

  primary: '#00B2A9',
  primary600: '#004744',
  primary500: '#006B65',
  primary400: '#008E87',
  primary300: '#00B2A9',
  primary200: '#80D9D4',
  primary100: '#B3E8E5',
  onPrimary: '#003230',
  primaryHover: '#00b2a926',

  secondary: '#0088FF',
  secondary600: '#1943B9',
  secondary500: '#1463D8',
  secondary400: '#0088FF',
  secondary300: '#2FA6FF',
  secondary200: '#8DCBFF',
  secondary100: '#BADFFF',
  onSecondary: '#0A1B4A',

  critical: '#E64B39',
  atRisk: '#FFC95C',
  good: '#46A669',
  noData: '#768294',

  chart1: '#3AB3FF',
  chart2: '#7AA9FF',
  chart3: '#9E7DD7',
  chart4: '#D772C7',
  chart5: '#FF68A6',
  chart6: '#FF5C69',
  chart7: '#FF8549',
  chart8: '#FFBF47',
  chart9: '#CBE364',
  chart10: '#72C0B3',

  pdfBackground: '#FFFFFF',
  pdfSurface1: '#F8F8FA',
  pdfSurface2: '#F0F1F4',
  pdfOutline: '#E0E3E9',
  pdfTextHighEmphasis: '#18212F',
  pdfTextMediumEmphasis: '#4B555B',
  pdfTextOnColor: '#FFFFFF',
  pdfPrimary: '#00B2A9',
  pdfPrimaryDark: '#006865',
  pdfPrimaryLight: '#B3E8E5',
  pdfSecondary: '#00B2A9',
  pdfSecondaryDark: '#00245B',
  pdfSecondaryLight: '#CCD5E3',
  pdfCritical: '#E64B39',
  pdfAtRisk: '#FFAB00',
  pdfGood: '#46A669',
  pdfNoData: '#768294',
  pdfChartPurple: '#5E63D2',
  pdfChartPink: '#D61C71',
  pdfChartOrange: '#EF4E00',
  pdfChartBlue: '#0074DE',

  level6: '#595C66',
  level8: '#A6A8AE',
};
export const lightTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  mode: 'light',
  colorSchemes: { dark: true, light: true },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 32,
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      fontStyle: 'normal',
      letterSpacing: 0.13,
    },
    h4: {
      fontWeight: 600,
      fontSize: 18,
      fontStyle: 'normal',
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    bodySmallBold: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    smallCaps: {
      fontWeight: 600,
      fontSize: 10,
      fontStyle: 'normal',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    mediumCaps: {
      fontWeight: 600,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    largeCaps: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    numbersXL: {
      fontWeight: 600,
      fontSize: 42,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersLarge: {
      fontWeight: 600,
      fontSize: 32,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersMedium: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersSmall: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 1.0,
    },
  },
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: seicLight.surface1,
      default: seicLight.background,
      primaryHover: seicLight.primaryHover,
      surface1: seicLight.surface1,
      surface2: seicLight.surface2,
      surface3: seicLight.surface3,
      surface4: seicLight.surface3,
    },
    indicator: {
      good: indicatorColors.good,
      warning: indicatorColors.warning,
      bad: indicatorColors.bad,
      notReporting: indicatorColors.notReporting,
      charging: indicatorColors.charging,
      discharging: indicatorColors.discharging,
      idle: indicatorColors.idle,
    },
    primary: {
      main: seicLight.primary,
      contrastText: seicLight.onPrimary,
    },
    surfaces: {
      surface1: seicLight.surface1,
    },
    secondary: {
      main: seicLight.secondary,
    },
    error: {
      main: seicLight.critical,
    },
    text: {
      default: seicLight.textHighEmphasis,
      secondary: seicLight.textMediumEmphasis,
      primary: seicLight.textHighEmphasis,
      disabled: seicLight.disabled,
    },
    action: {
      disabledBackground: seicLight.onSurface1,
      disabled: seicLight.disabled,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: 1.0,
        },
        ButtonBase: {
          disabled: {
            backgroundColor: seicLight.disabled,
            color: seicLight.textHighEmphasis,
          },
        },
        containedPrimary: {
          backgroundColor: seicLight.primary,
          color: seicLight.onPrimary,
        },
        outlined: {
          borderColor: seicLight.primary,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          color: seicLight.textHighEmphasis,
          backgroundColor: seicLight.onSurface1,
          backgroundImage: 'none',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: seicLight.textHighEmphasis,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: seicLight.surface1,

          '&.Mui-selected': {
            backgroundColor: seicLight.primary,
            color: seicLight.onPrimary,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: seicLight.tooltipBackground,
          color: seicLight.textHighEmphasis,
        },
      },
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        padding: '32px',
        background: seicLight.surface1,
      },
    },
    MuiIcon: {
      colorPrimary: seicLight.textMediumEmphasis,
      colorSecondary: seicLight.textMediumEmphasis,
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: seicLight.textMediumEmphasis,
      },
      colorSecondary: {
        color: seicLight.textMediumEmphasis,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      head: {
        fontSize: 16,
        color: seicLight.textMediumEmphasis,
      },
    },
    MuiButton: {
      contained: {
        color: seicLight.onPrimary,
        backgroundColor: seicLight.primary,
      },
      textSecondary: {
        color: seicLight.textLowEmphasis,
      },
      containedSecondary: {
        color: seicLight.onSurface2,
        backgroundColor: seicLight.surface2,
      },
      outlined: {
        border: `1px solid ${seicLight.secondary}`,
      },
    },
    MuiSelect: {
      // see https://github.com/mui-org/material-ui/issues/9826
      select: {
        padding: undefined,
        //paddingRight: theme.spacing(4),
        height: undefined,
      },
      selectMenu: {
        lineHeight: undefined,
      },
    },
    appVar: {
      backgroundColor: seicLight.surface1,
    },
  },
});

export const darkTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  mode: 'dark',
  colorSchemes: { light: true, dark: true },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 32,
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      fontStyle: 'normal',
      letterSpacing: 0.13,
    },
    h4: {
      fontWeight: 600,
      fontSize: 18,
      fontStyle: 'normal',
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    bodySmallBold: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    smallCaps: {
      fontWeight: 600,
      fontSize: 10,
      fontStyle: 'normal',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    mediumCaps: {
      fontWeight: 600,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    largeCaps: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    numbersXL: {
      fontWeight: 600,
      fontSize: 42,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersLarge: {
      fontWeight: 600,
      fontSize: 32,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersMedium: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersSmall: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 1.0,
    },
  },
  palette: {
    mode: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: seicDark.surface1,
      default: seicDark.background,
      primaryHover: seicDark.primary,
      surface1: seicDark.surface1,
      surface2: seicDark.surface2,
      surface3: seicDark.surface3,
      surface4: seicDark.surface4,
    },
    primary: {
      main: seicDark.primary,
      contrastText: seicDark.onPrimary,
    },
    surfaces: {
      surface1: seicDark.surface1,
    },
    secondary: {
      main: seicDark.secondary,
    },
    error: {
      main: seicDark.critical,
    },
    indicator: {
      good: indicatorColors.good,
      warning: indicatorColors.warning,
      bad: indicatorColors.bad,
      notReporting: indicatorColors.notReporting,
      charging: indicatorColors.charging,
      discharging: indicatorColors.discharging,
      idle: indicatorColors.idle,
    },
    text: {
      default: seicDark.textHighEmphasis,
      secondary: seicDark.textMediumEmphasis,
      primary: seicDark.textHighEmphasis,
      disabled: seicDark.disabled,
    },
    action: {
      disabledBackground: seicDark.onSurface1,
      disabled: seicDark.disabled,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: 1.0,
        },
        ButtonBase: {
          disabled: {
            backgroundColor: seicDark.disabled,
            color: seicDark.textHighEmphasis,
          },
        },
        containedPrimary: {
          backgroundColor: seicDark.primary,
          color: seicDark.onPrimary,
        },
        outlined: {
          borderColor: seicDark.primary,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          color: seicDark.textHighEmphasis,
          backgroundColor: seicDark.onSurface1,
          backgroundImage: 'none',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: seicDark.textHighEmphasis,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: seicDark.surface1,

          '&.Mui-selected': {
            backgroundColor: seicDark.primary,
            color: seicDark.onPrimary,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: seicDark.tooltipBackground,
          color: seicDark.textHighEmphasis,
        },
      },
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        padding: '32px',
        background: seicDark.surface1,
      },
    },
    MuiIcon: {
      colorPrimary: seicDark.textMediumEmphasis,
      colorSecondary: seicDark.textMediumEmphasis,
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: seicDark.textMediumEmphasis,
      },
      colorSecondary: {
        color: seicDark.textMediumEmphasis,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      head: {
        fontSize: 16,
        color: seicDark.textMediumEmphasis,
      },
    },
    MuiButton: {
      contained: {
        color: seicDark.onPrimary,
        backgroundColor: seicDark.primary,
      },
      textSecondary: {
        color: seicDark.textLowEmphasis,
      },
      containedSecondary: {
        color: seicDark.onSurface2,
        backgroundColor: seicDark.surface2,
      },
      outlined: {
        border: `1px solid ${seicDark.secondary}`,
      },
    },
    MuiSelect: {
      // see https://github.com/mui-org/material-ui/issues/9826
      select: {
        padding: undefined,
        //paddingRight: theme.spacing(4),
        height: undefined,
      },
      selectMenu: {
        lineHeight: undefined,
      },
    },
    appVar: {
      backgroundColor: seicDark.surface1,
    },
  },
});

export const darkModeMapStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];
