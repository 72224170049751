import {
  BatteryStdTwoTone,
  Business,
  FolderShared,
  LineAxis,
  ListSharp,
  People,
  TableView,
  Task,
} from '@mui/icons-material';
import { lazy } from 'react';

const BatteryWidget = lazy(() => import('../BatteryWidget/BatteryWidget'));
const BatteryWidgetConfig = lazy(() => import('../BatteryWidget/BatteryWidgetConfig'));
const SitesWidget = lazy(() => import('../SitesWidget/SitesWidget'));
const SitesWidgetConfig = lazy(() => import('../SitesWidget/SitesWidgetConfig'));
const TaskBurnDownWidget = lazy(() => import('../TaskBurnDownWidget/TaskBurndownWidget'));
const TaskBurnDownWidgetConfig = lazy(
  () => import('../TaskBurnDownWidget/TaskBurndownWidgetConfig')
);
const TeamsWidget = lazy(() => import('../TeamsWidget/TeamsWidget'));
const TeamsWidgetConfig = lazy(() => import('../TeamsWidget/TeamsWidgetConfig'));
const ProjectGoalsWidget = lazy(() => import('../ProjectGoalsWidget/ProjectGoalsWidget'));
const ProjectGoalsWidgetConfig = lazy(
  () => import('../ProjectGoalsWidget/ProjectGoalsWidgetConfig')
);
const TeamTreeWidget = lazy(() => import('../TeamTreeWidget/TeamTreeWidget'));
const TeamTreeWidgetConfig = lazy(() => import('../TeamTreeWidget/TeamTreeWidgetConfig'));

const SitesTableWidget = lazy(() => import('../SitesTableWidget/SitesTableWidget'));
const SitesTableWidgetConfig = lazy(() => import('../SitesTableWidget/SitesTableWidgetConfig'));

const FacilitiesByTypeWidget = lazy(
  () => import('../FacilitiesByTypeWidget/FacilitiesByTypeWidget')
);
const FacilitiesByTypeWidgetConfig = lazy(
  () => import('../FacilitiesByTypeWidget/FacilitiesByTypeWidgetConfig')
);
const ProjectsByTypeWidget = lazy(() => import('../ProjectsByTypeWidget/ProjectsByTypeWidget'));
const ProjectsByTypeWidgetConfig = lazy(
  () => import('../ProjectsByTypeWidget/ProjectsByTypeWidgetConfig')
);
const ProjectStatusWidget = lazy(() => import('../ProjectStatusWidget/ProjectStatusWidget'));
const ProjectStatusWidgetConfig = lazy(
  () => import('../ProjectStatusWidget/ProjectStatusWidgetConfig')
);
const TopClientsWidget = lazy(() => import('../TopClientsWidget/TopClientsWidget'));
const TopClientsWidgetConfig = lazy(() => import('../TopClientsWidget/TopClientsWidgetConfig'));
const ProjectsTableWidget = lazy(() => import('../ProjectsTableWidget/ProjectsTableWidget'));
const ProjectsTableWidgetConfig = lazy(
  () => import('../ProjectsTableWidget/ProjectsTableWidgetConfig')
);

export const widgets = {
  battery: {
    title: 'Battery',
    description: 'Monitor battery levels',
    icon: BatteryStdTwoTone,
    defaultConfig: {},
    component: BatteryWidget,
    config: BatteryWidgetConfig,
    tags: ['all', 'battery'],
  },

  sites: {
    title: 'Facilities Map',
    description: 'Monitor facilities on map',
    icon: Business,
    component: SitesWidget,
    config: SitesWidgetConfig,
    tags: ['all', 'sites'],
    disableRipple: true,
  },
  teamTree: {
    title: 'Teams list controller',
    description: 'Used to control other widgets based on teams',
    icon: People,
    component: TeamTreeWidget,
    config: TeamTreeWidgetConfig,
    disableRipple: true,
    tags: ['all', 'projects', 'sales'],
  },
  teams: {
    title: 'Projects by team',
    description: 'Used to control other widgets based on teams',
    icon: FolderShared,
    component: TeamsWidget,
    config: TeamsWidgetConfig,
    tags: ['all', 'projects', 'sales'],
  },
  sitesTable: {
    title: 'Sites table',
    description: 'View sites in monitor',
    icon: TableView,
    component: SitesTableWidget,
    config: SitesTableWidgetConfig,
    disableRipple: true,
    tags: ['all', 'projects', 'sales', 'sites'],
  },
  projectsTable: {
    title: 'Projects table',
    description: 'View projects table',
    icon: TableView,
    component: ProjectsTableWidget,
    config: ProjectsTableWidgetConfig,
    tags: ['all', 'projects', 'sales'],
  },
  projectGoals: {
    title: 'Project goals',
    description: 'Display project initiation against team goals',
    icon: LineAxis,
    component: ProjectGoalsWidget,
    config: ProjectGoalsWidgetConfig,
    tags: ['all', 'projects', 'sales'],
  },
  taskBurnDown: {
    title: 'Task Burn down',
    description: 'development team task burn down by label',
    icon: Task,
    component: TaskBurnDownWidget,
    config: TaskBurnDownWidgetConfig,
    tags: ['all', 'development'],
  },

  projectStatus: {
    title: 'Project Status',
    description: 'Shows an overview of project status',
    icon: LineAxis,
    component: ProjectStatusWidget,
    config: ProjectStatusWidgetConfig,
    tags: ['all', 'projects', 'sales'],
  },
  topClients: {
    title: 'Top Clients',
    description: 'Get the clients with the most projects',
    icon: LineAxis,
    defaultConfig: { chartType: 'bar' },
    component: TopClientsWidget,
    config: TopClientsWidgetConfig,
    tags: ['all', 'sales'],
  },
  facilitiesByType: {
    title: 'Facilities by Type',
    description: 'Get the facilities by type',
    icon: LineAxis,
    defaultConfig: { chartType: 'bar' },
    component: FacilitiesByTypeWidget,
    config: FacilitiesByTypeWidgetConfig,
    tags: ['all', 'sales'],
  },
  projectsByType: {
    title: 'Projects by Type',
    description: 'How many projects are there by type (measure, model, monitor)',
    icon: LineAxis,
    defaultConfig: { chartType: 'pie' },
    component: ProjectsByTypeWidget,
    config: ProjectsByTypeWidgetConfig,
    tags: ['all', 'sales'],
  },
};
