import { ArrowBack, Close, Widgets } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { uniq } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Dashboard,
  DashboardWidget,
  useUpdateDashboardMutation,
} from '../services/projects/dashboardsApi';
import WidgetErrorBoundary from './widgets/WidgetErrorBoundary';

import { widgets } from './widgets/utils/widgetList';

type Props = {
  dashboard: Dashboard;
  open: boolean;
  toEdit: DashboardWidget | null;
  onClose: () => void;
};

const WidgetPicker = ({ dashboard, open, toEdit, onClose }: Props) => {
  const [filter, setFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [mounted, setMounted] = useState(false);

  const [selectedWidget, setSelectedWidget] = useState<(typeof widgets)['battery'] | null>(null);
  const [editable, setEditable] = useState<DashboardWidget>({});
  const [updateDashboard, { isLoading }] = useUpdateDashboardMutation();

  const { categories, cards } = useMemo(() => {
    const searchTerm = filter.toLowerCase();
    let items = Object.values(widgets).filter((w) => {
      return (w.title + w.description).toLowerCase().includes(searchTerm);
    });
    const _categories = uniq(items.flatMap((c) => c.tags));
    items = items.filter((item) => item.tags.includes(selectedCategory));

    if (_categories.length && !_categories.includes(selectedCategory)) {
      setSelectedCategory(_categories[0]);
    }
    return { cards: items, categories: _categories };
  }, [filter, selectedCategory]);

  const [WidgetConfigComponent, WidgetComponent] = useMemo(() => {
    const widget = selectedWidget;
    if (!widget) return [null, null];
    return [widget.config, widget.component];
  }, [selectedWidget]);

  useLayoutEffect(() => {
    // HACK: this is necessary because for some reason the width of the slider doesnt adjust properly on first render
    setTimeout(() => {
      setMounted(true);
    }, 0);
  }, []);

  useEffect(() => {
    if (toEdit) {
      const widget = widgets[toEdit.type];
      setSelectedWidget(widget);
      setEditable(structuredClone(toEdit));
    }
  }, [toEdit]);

  const handleCardClick = (card: any) => {
    setSelectedWidget(card);
    const config = { title: card.title, ...(card.defaultConfig ?? {}) };
    setEditable({ config, title: card.title });
  };

  const handleConfigChange = (key: string, value: any) => {
    const tmp = structuredClone(editable);
    setEditable({ ...tmp, config: { ...tmp.config, [key]: value } });
  };

  const findWidgetPossiblePosition = (w: number, h: number) => {
    // Given a grid of 8xn, find the first available position in the current dashboard
    const { widgets } = dashboard.data;
    let x = 0;
    let y = 0;
    let found = false;

    const isPositionAvailable = (x: number, y: number, w: number, h: number) => {
      return !widgets.some(
        (widget) =>
          x < widget.x + widget.w && x + w > widget.x && y < widget.y + widget.h && y + h > widget.y
      );
    };

    while (!found) {
      if (isPositionAvailable(x, y, w, h)) {
        found = true;
      } else {
        x++;
        if (x >= 8) {
          x = 0;
          y++;
        }
      }
    }

    return { x, y, w, h };
  };
  const handleSave = async () => {
    try {
      const data = structuredClone(dashboard.data);
      data.widgets = data.widgets ?? [];

      if (editable.id) {
        data.widgets = data.widgets.map((w) => (w.id === editable.id ? editable : w));
      } else {
        // Add new widget with default position
        const position = findWidgetPossiblePosition(2, 2);
        const type = selectedWidget
          ? (Object.entries(widgets).find(([, w]) => w.title === selectedWidget.title)?.[0] ?? '')
          : '';
        data.widgets.push({
          config: editable.config,
          id: '' + new Date().getTime(),
          ...position,
          type,
        });
      }
      await updateDashboard({
        ...dashboard,
        data,
      }).unwrap();
      enqueueSnackbar('Widget added', { variant: 'success' });
      onClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error adding widget', { variant: 'error' });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
    >
      <DialogContent sx={{ overflowX: 'hidden' }}>
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box
            sx={{
              transform: selectedWidget ? 'translateX(-50%)' : 'translateX(0)',
              transition: 'transform 0.3s ease-in-out',
              width: mounted ? '200%' : '100%',
              display: 'flex',
            }}
          >
            <Grid2
              id="searchContainer"
              container
              spacing={2}
              flex={1}
              sx={{ opacity: selectedWidget ? 0 : 1, transition: 'opacity, 0.3s ease-in-out' }}
            >
              <Grid2 size={{ xs: 5, sm: 4, md: 3 }}>
                <Typography variant="h2" sx={{ padding: '6px 0', marginBottom: '16px' }}>
                  Add Widget
                </Typography>
                <Tabs
                  orientation="vertical"
                  value={selectedCategory}
                  onChange={(_, value) => setSelectedCategory(value)}
                >
                  {categories.map((category, index) => (
                    <Tab
                      sx={{
                        backgroundColor: selectedCategory === category ? 'primary.main' : 'inherit',
                        color: selectedCategory === category ? 'white!important' : 'inherit',
                      }}
                      key={index}
                      value={category}
                      label={category}
                      onClick={() => setSelectedCategory(category)}
                    ></Tab>
                  ))}
                </Tabs>
                {!categories.length && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="200px"
                    width="100%"
                    textAlign="center"
                    sx={{ color: 'text.secondary' }}
                  >
                    <Typography variant="h6">No categories available</Typography>
                  </Box>
                )}
              </Grid2>
              <Grid2 size={{ xs: 7, sm: 8, md: 9 }}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Search"
                    variant="outlined"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <IconButton onClick={onClose}>
                    <Close />
                  </IconButton>
                </Box>
                <Grid2 container spacing={2}>
                  {cards.map((card, index) => (
                    <Grid2 size={{ sm: 6, md: 4 }} key={index}>
                      <Card variant="outlined" sx={{ width: '100%' }}>
                        <CardActionArea onClick={() => handleCardClick(card)}>
                          <CardContent sx={{ textAlign: 'center' }}>
                            <card.icon sx={{ width: '40px', height: 'auto' }} />
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid2>
                  ))}
                  {!cards.length && (
                    <Box
                      display="flex"
                      flexDirection={'column'}
                      justifyContent="center"
                      alignItems="center"
                      height="400px"
                      width="100%"
                      textAlign="center"
                      sx={{ color: 'text.secondary' }}
                    >
                      <Widgets sx={{ height: 100, width: 'auto' }} />
                      <Typography variant="h6">No widgets found</Typography>
                      <Typography variant="body2">
                        Try searching searching for something else
                      </Typography>
                    </Box>
                  )}
                </Grid2>
              </Grid2>
            </Grid2>
            <Box
              id="configContainer"
              sx={{
                flex: 1,
                opacity: selectedWidget ? 1 : 0,
                transition: 'opacity, 0.3s ease-in-out',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {!toEdit && (
                  <IconButton onClick={() => setSelectedWidget(null)}>
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography variant="h2">{selectedWidget?.title}</Typography>
              </Box>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Typography variant="h4" sx={{ mb: '1em' }}>
                    {toEdit ? 'Edit' : 'Configuration'} Widget
                  </Typography>
                  <Box sx={{ display: 'grid', gap: '1em' }}>
                    <TextField
                      label="Widget Title (optional)"
                      fullWidth
                      autoFocus
                      value={editable?.config?.title ?? ''}
                      onChange={(e) => handleConfigChange('title', e.target.value)}
                    />
                    {WidgetConfigComponent && (
                      <WidgetConfigComponent onChange={handleConfigChange} {...editable.config} />
                    )}
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <WidgetErrorBoundary>
                    <Typography variant="h4">Preview</Typography>
                    <Box sx={{ p: 2, height: '400px', overflow: 'auto' }}>
                      {WidgetComponent && <WidgetComponent {...editable?.config} />}
                    </Box>
                  </WidgetErrorBoundary>
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {!!selectedWidget && (
          <Grid2 container spacing={2} flex={1}>
            <Grid2 size={{ xs: 12, md: 6 }} textAlign={'right'}>
              <Button size="large" onClick={onClose} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSave}
                loading={isLoading}
                disabled={!selectedWidget}
              >
                Save
              </LoadingButton>
            </Grid2>
          </Grid2>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WidgetPicker;
