import { Delete, DriveFileRenameOutline, FileCopy, Star, Tune } from '@mui/icons-material';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { useDialogs } from '@toolpad/core';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dashboard,
  useCreateDashboardMutation,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} from '../../services/projects/dashboardsApi';
import { useUpdateUserDetailsMutation } from '../../services/projects/projectsApi';
import { indicatorColors } from '../../themes';

type Props = {
  dashboard: Dashboard;
  children?: React.ReactNode;
  onChange?: (dashboard: Dashboard) => void;
  onDelete?: () => void;
  onClone?: (dashboard: Dashboard) => void;
  onSetAsDefault?: () => void;
};
const DashboardMenu = ({
  dashboard,
  children,
  onChange,
  onDelete,
  onClone,
  onSetAsDefault,
}: Props) => {
  const dialogs = useDialogs();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const [updateDashboard, { isLoading: isUpdating }] = useUpdateDashboardMutation();
  const [deleteDashboard, { isLoading: isDeleting }] = useDeleteDashboardMutation();
  const [createDashboard, { isLoading: isCreating }] = useCreateDashboardMutation();
  const [updateUserDetails, { isLoading: isSettingDefault }] = useUpdateUserDetailsMutation();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRename = async (e: any) => {
    e.stopPropagation();
    handleMenuClose();
    const newName = await dialogs.prompt('Enter new dashboard name', {
      title: 'Rename Dashboard',
      okText: 'Rename',
      severity: 'info',
    });
    if (newName) {
      try {
        await updateDashboard({
          ...dashboard!,
          name: newName,
        }).unwrap();
        enqueueSnackbar('Dashboard renamed', { variant: 'info' });
        onChange?.({ ...dashboard!, name: newName });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Error renaming dashboard', { variant: 'error' });
      }
    }
  };

  const handleSetAsDefault = async (e: any) => {
    e.stopPropagation();
    handleMenuClose();
    try {
      await updateUserDetails({
        defaultDashboard: dashboard!.id,
      }).unwrap();
      enqueueSnackbar(
        dashboard?.is_default ? 'Dashboard removed as default' : 'Dashboard set as default',
        { variant: 'info' }
      );
      onSetAsDefault?.();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error setting as default dashboard', { variant: 'error' });
    }
  };

  const handleClone = async (e: any) => {
    e.stopPropagation();
    handleMenuClose();
    try {
      const newName = await dialogs.prompt('Enter new dashboard name', {
        title: 'Cloned Dashboard name',
        okText: 'Clone',
      });
      const data = await createDashboard({
        ...structuredClone(dashboard),
        name: newName,
      }).unwrap();
      enqueueSnackbar('Dashboard cloned', { variant: 'info' });
      onClone?.(data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error cloning dashboard', { variant: 'error' });
    }
  };

  const handleDelete = async (e: any) => {
    e.stopPropagation();
    handleMenuClose();
    const confirm = await dialogs.confirm('Are you sure you want to delete this dashboard?', {
      title: 'Delete Dashboard',
      okText: 'Delete',
      severity: 'error',
    });
    if (confirm) {
      try {
        await deleteDashboard(dashboard!.id).unwrap();
        navigate('/dashboard');
        enqueueSnackbar('Dashboard deleted', { variant: 'info' });
        onDelete?.();
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Error deleting dashboard', { variant: 'error' });
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        {children || <Tune />}
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose} onclick>
        <MenuItem onClick={handleSetAsDefault}>
          <Star sx={{ mr: 1 }} /> Set as default dashboard
        </MenuItem>
        <MenuItem onClick={handleRename}>
          <DriveFileRenameOutline sx={{ mr: 1 }} /> Rename dashboard
        </MenuItem>
        <MenuItem onClick={handleClone}>
          <FileCopy sx={{ mr: 1 }} /> Clone dashboard
        </MenuItem>
        <Divider />
        <MenuItem sx={{ color: indicatorColors.bad }} onClick={handleDelete}>
          <Delete sx={{ mr: 1 }} />
          Delete dashboard
        </MenuItem>
      </Menu>
    </>
  );
};

export default DashboardMenu;
